@import '~antd/dist/antd.less';

@layout-body-background: #F0F2F5;
@layout-header-background: linear-gradient(180deg, rgba(244,81,30,1) 0%, rgba(255,142,106,1) 100%);
@menu-inline-toplevel-item-height: 45px;
@border-radius-base: 5px;
body {
  height: 100vh;
}

html {
  height: max-content;
}